module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-fontawesome-css@1.2.0_3vst3jqzcm2oo2ayawas3fndvy/node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-omni-font-loader@2.0.2_isgaalejxodrf4fwlfb5fwtihq/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Work Sans","file":"https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.8.1_2e45v5snrxhxxj6zpu56o774dy/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
